import React, { useRef, useReducer, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";
import * as Common from "./Common";

const initialState = {
  image_url: "",
  image_ext: "JPG",
};

const maxUploadSize = 102400;

function reducer(state, action) {
  switch (action.type) {
    case "image_url":
      return {
        ...state,
        image_url: action.payload,
      };
    case "image_ext":
      return {
        ...state,
        image_ext: action.payload,
      };
    default:
      throw new Error();
  }
}

function get_image_ext(image) {
  let image_type = image.split(",")[0].toLowerCase();
  let image_ext = "JPG";
  if (image_type.indexOf("image/png") > -1) {
    image_ext = "PNG";
  } else if (image_type.indexOf("image/jpg") > -1) {
    image_ext = "JPG";
  } else if (image_type.indexOf("image/jpeg") > -1) {
    image_ext = "JPG";
  } else if (image_type.indexOf("image/gif") > -1) {
    image_ext = "GIF";
  } else if (image_type.indexOf("image/webp") > -1) {
    image_ext = "WEBP";
  }

  return image_ext;
}

function SearchForm(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const inputFileRef = useRef(null);
  const searchBtnRef = useRef(null);

  const navigate = useNavigate();

  const triggerFileBoxClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
    searchBtnRef.current.focus();
  };

  const searchClick = () => {
    var img_data_url = Common.getImageDataUrl();
    if (img_data_url && img_data_url !== "") {
      navigate("../searchresult", { replace: true });
    } else {
      navigate("../", { replace: true });
    }
  };

  const removeStoredImage = () => {
    Common.setImageDataUrl("");
    dispatch({
      type: "image_url",
      payload: "",
    });
    navigate("../", { replace: true });
  };

  const handleImageChange = (evt) => {
    evt.preventDefault();

    var tgt = evt.target || window.event.srcElement,
      files = tgt.files;

    if (files[0] && files[0].size < maxUploadSize) {
      console.log("files[0].size:", files[0].size);
      var fr = new FileReader();
      fr.onload = function () {
        Common.setImageDataUrl(fr.result);

        if (props.onChangeImage) {
          dispatch({
            type: "image_url",
            payload: fr.result,
          });

          dispatch({ type: "image_ext", payload: get_image_ext(fr.result) });
          props.onChangeImage();
        } else {
          navigate("../searchresult", { replace: true });
        }
      };
      fr.readAsDataURL(files[0]);
    } else {
      alert("Please upload PNG, JPG, GIF or WebP with less than 100KB");
    }
  };

  useEffect(() => {
    function refreshImage() {
      let image = Common.getImageDataUrl();

      if (image && image !== "") {
        dispatch({
          type: "image_url",
          payload: image,
        });

        dispatch({
          type: "image_ext",
          payload: get_image_ext(image),
        });
      } else {
        navigate("../", { replace: true });
      }
    }

    refreshImage();
    if (props.refreshImageRef) {
      props.refreshImageRef.current = refreshImage;
    }
  }, [props.refreshImageRef, navigate]);

  return (
    <Form>
      <Row className="align-items-center">
        <Col sm={9} className="mt-2">
          <InputGroup>
            {state.image_url && state.image_url !== "" ? (
              <div className="image-pill">
                <div className="image-div">
                  <img
                    src={state.image_url}
                    className="img-fluid"
                    alt="AliScraper"
                  />
                </div>
                <div className="txt-div">
                  &nbsp;{state.image_ext}&nbsp;&nbsp;
                  <i className="bi bi-x-lg" onClick={removeStoredImage}></i>
                </div>
              </div>
            ) : (
              ""
            )}

            <Form.Control
              type="text"
              placeholder={
                state.image_url && state.image_url !== ""
                  ? ""
                  : "Search products by image..."
              }
              style={{ padding: ".9rem .75rem", background: "#fff" }}
              onClick={triggerFileBoxClick}
              readOnly="readonly"
            />

            <input
              type="file"
              ref={inputFileRef}
              className="fileSelection"
              accept="image/*"
              onChange={handleImageChange}
            />

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tooltip-bottom`}>Search by image</Tooltip>}
            >
              <Button
                variant="outline-light"
                id="button-addon2"
                style={{
                  border: "1px solid #ced4da",
                  borderLeft: "0px",
                  background: "#fff",
                  color: "#000",
                }}
                onClick={triggerFileBoxClick}
              >
                <i className="bi bi-camera-fill"></i>
              </Button>
            </OverlayTrigger>
          </InputGroup>
        </Col>
        <Col sm={3} className="mt-2">
          <Button
            variant="primary"
            className="image-search-button"
            type="submit"
            style={{ paddingTop: "14px", paddingBottom: "14px" }}
            onClick={searchClick}
            ref={searchBtnRef}
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default SearchForm;
