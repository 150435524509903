exports.setImageDataUrl = (img_data_url) => {
  try {
    window.localStorage.setItem("aliscraper_img_data_url", img_data_url);
  } catch (ex) {
    if (document.getElementById("aliscraper_img_data_url_hidden")) {
      document.getElementById("aliscraper_img_data_url_hidden").value =
        img_data_url;
    }
  }
};

exports.getImageDataUrl = () => {
  let image_url = "";
  try {
    image_url = window.localStorage.getItem("aliscraper_img_data_url");
  } catch (ex) {
    image_url = document.getElementById("aliscraper_img_data_url_hidden")
      ? document.getElementById("aliscraper_img_data_url_hidden").value
      : "";
  }

  return image_url;
};
