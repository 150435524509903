import React, { useRef, useEffect, useReducer } from "react";
import logo from "./../assets/img/logo.svg";
import { Row, Col, Container, Stack, Spinner } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import SearchForm from "./SearchForm";
import ProductList from "./ProductList";
import NoImport from "./../assets/img/no-import.svg";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import * as Common from "./Common";

const initialState = {
  product_list: null,
  request_count: 0,
  loading: true,
  error: null,
  image_data: null,
  iniframe: false,
};

const maxUploadSize = 102400;

function reducer(state, action) {
  switch (action.type) {
    case "product_list":
      return {
        ...state,
        product_list: action.payload,
      };
    case "request_count":
      return {
        ...state,
        request_count: state.request_count + 1,
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };

    case "iniframe":
      return {
        ...state,
        iniframe: action.payload,
      };

    case "error":
      return {
        ...state,
        error: action.payload,
      };
    default:
      throw new Error();
  }
}

const base64Resize = (sourceBase64, maxWidth, callBack) => {
  var _scale = 0;
  var img = document.createElement("img");
  img.setAttribute("src", sourceBase64);

  img.onload = () => {
    _scale = maxWidth / img.width;

    var canvas = document.createElement("canvas");
    canvas.width = img.width * _scale;
    canvas.height = img.height * _scale;

    var ctx = canvas.getContext("2d");
    var maxW = img.width * _scale;
    var maxH = img.height * _scale;

    var iw = img.width;
    var ih = img.height;
    var scl = Math.min(maxW / iw, maxH / ih);
    var iwScaled = iw * scl;
    var ihScaled = ih * scl;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
    const newBase64 = canvas.toDataURL("image/jpeg", scl);

    callBack(newBase64);
  };
};

const toDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      var data_url = reader.result;
      var head = data_url.split(",")[0];
      var imgFileSize = Math.round(((data_url.length - head.length) * 3) / 4);
      if (imgFileSize < maxUploadSize) {
        Common.setImageDataUrl(reader.result);
        callback(reader.result);
      } else {
        base64Resize(reader.result, 500, function (img_data_url) {
          Common.setImageDataUrl(img_data_url);
          callback(img_data_url);
        });
      }
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const fetchData = async () => {
  try {
    window.analytics.track("imagesearch__btn--clicked");

    let url;
    if (process.env.REACT_APP_NODE_ENV === "local") {
      url = process.env.REACT_APP_LOCAL_API_URL;
    } else {
      url = process.env.REACT_APP_PRODUCTION_API_URL;
    }

    url += "?feature=image-search";

    console.log("url:", url);

    let image_data_url = Common.getImageDataUrl();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        image_data_url: image_data_url ? image_data_url : null,
        method: "POST",
      }),
    };

    let productData = await fetch(url, requestOptions);
    let productDataJson = await productData.json();

    return productDataJson;
  } catch (error) {
    console.log(error);
  }
};

function SearchResult() {
  const [state, dispatch] = useReducer(reducer, initialState);
  let { image_url } = useParams();
  const refreshImageRef = useRef(null);

  const onChangeImage = () => {
    dispatch({ type: "loading", payload: true });
    dispatch({ type: "error", payload: "" });
    dispatch({ type: "product_list", payload: null });

    fetchData(image_url).then((res) => {
      if (
        res &&
        res.data &&
        res.data.products &&
        res.data.products.traffic_image_product_d_t_o
      ) {
        dispatch({
          type: "product_list",
          payload: res.data.products.traffic_image_product_d_t_o,
        });
      } else {
        if (res && res.result && res.result.is_finished) {
          dispatch({
            type: "error",
            payload: "No products found. Please try with another product.",
          });
        } else {
          dispatch({
            type: "error",
            payload: "Error occured while fetching products. Please try again.",
          });
        }
      }
      dispatch({ type: "loading", payload: false });
    });
  };

  useEffect(() => {
    if (window.location !== window.parent.location) {
      dispatch({ type: "iniframe", payload: true });
    } else {
      dispatch({ type: "iniframe", payload: false });
    }

    dispatch({ type: "error", payload: "" });

    function fetch_data() {
      fetchData().then((res) => {
        if (
          res &&
          res.data &&
          res.data.products &&
          res.data.products.traffic_image_product_d_t_o
        ) {
          dispatch({
            type: "product_list",
            payload: res.data.products.traffic_image_product_d_t_o,
          });
        } else {
          if (res && res.result && res.result.is_finished) {
            dispatch({
              type: "error",
              payload: "No products found. Please try with another product.",
            });
          } else {
            dispatch({
              type: "error",
              payload:
                "Error occured while fetching products. Please try again.",
            });
          }
        }
        dispatch({ type: "loading", payload: false });
      });
    }

    if (image_url) {
      toDataUrl(image_url, function () {
        refreshImageRef.current();
        fetch_data();
      });
    } else {
      fetch_data();
    }
  }, [image_url]);
  return (
    <div className="App">
      <Container className="" style={{ maxWidth: "1240px" }}>
        <div className="py-5 border-bottom">
          <Row
            className="justify-content-md-center mx-auto"
            style={{ maxWidth: "800px" }}
          >
            <Col md="auto" className="mt-2">
              <Stack
                direction="horizontal"
                gap={3}
                style={{ marginTop: "14px", marginBottom: "14px" }}
              >
                <Link to="/" className="logo-link">
                  {state.iniframe === true ? (
                    <span className="spocket-txt-logo">
                      Spocket Image Search
                    </span>
                  ) : (
                    <img
                      src={logo}
                      alt="AliScraper"
                      className="img-fluid"
                      style={{ height: "28px" }}
                    />
                  )}
                </Link>
              </Stack>
            </Col>
            <Col>
              <SearchForm
                image="true"
                onChangeImage={onChangeImage}
                refreshImageRef={refreshImageRef}
              />
            </Col>
          </Row>
        </div>

        <div className="py-5 border-bottom">
          <Row>
            <Col className="result-title">
              {state.error ? (
                <center>No products found!</center>
              ) : (
                "Similar Products"
              )}
            </Col>
          </Row>
          {state.loading === false && state.product_list ? (
            <ProductList
              product_list={state.product_list}
              import_btn_click_event="imagesearch__add-to-import-pc--clicked"
            />
          ) : (
            <Row>
              {state.error ? (
                <Col style={{ textAlign: "center" }}>
                  <p>{state.error}</p>
                  <img
                    src={NoImport}
                    alt="No Import"
                    className="img-fluid"
                    style={{ height: "450px" }}
                  />
                </Col>
              ) : (
                <Col>
                  <div className="spinner-container">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
}
export default SearchResult;
