import React, { useEffect, useReducer } from "react";

import { useParams } from "react-router";

import { Container, Row, Col, Stack, Button, Spinner } from "react-bootstrap";
import logo from "./../assets/img/logo.svg";
import NoImport from "./../assets/img/no-import.svg";
import ProductList from "./ProductList";

const initialState = {
  product_list: null,
  request_count: 0,
  loading: true,
  error: null,
  product_url: null,
  product_name: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "product_list":
      return {
        ...state,
        product_list: action.payload,
      };
    case "request_count":
      return {
        ...state,
        request_count: state.request_count + 1,
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    case "product_name":
      return {
        ...state,
        product_name: action.payload,
      };
    case "product_url":
      return {
        ...state,
        product_url: action.payload,
      };

    case "error":
      return {
        ...state,
        error: action.payload,
      };
    default:
      throw new Error();
  }
}

const fetchData = async (
  product_id,
  title,
  category_id,
  root_category_id,
  country
) => {
  try {

    window.analytics.track("recommendation__btn--clicked");

    let url;
    if (process.env.REACT_APP_NODE_ENV === "local") {
      url = process.env.REACT_APP_LOCAL_API_URL;
    } else {
      url = process.env.REACT_APP_PRODUCTION_API_URL;
    }

    url += "?feature=recommendation";

    if (product_id) {
      url += "&product_id=" + encodeURIComponent(product_id);
    }

    if (title) {
      url += "&title=" + encodeURIComponent(title);
    }

    if (category_id) {
      url += "&category_id=" + encodeURIComponent(category_id);
    }

    if (root_category_id) {
      url += "&root_category_id=" + encodeURIComponent(root_category_id);
    }

    if (country) {
      url += "&country=" + encodeURIComponent(country);
    }

    console.log("url:", url);

    let productData = await fetch(url);
    let productDataJson = await productData.json();

    return productDataJson;
  } catch (error) {
    console.log(error);
  }
};

const Recommendation = (props) => {
  let { product_id, title, category_id, root_category_id, country } =
    useParams();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchData(product_id, title, category_id, root_category_id, country).then(
      (res) => {
        if (
          res &&
          res.result &&
          res.result.products &&
          res.result.products.integer
        ) {
          dispatch({
            type: "product_list",
            payload: res.result.products.integer,
          });

          dispatch({
            type: "product_url",
            payload:
              "https://www.aliexpress.com/item/" + res.product_id + ".html",
          });

          dispatch({
            type: "product_name",
            payload: res.product_name,
          });
        } else {
          if (res && res.result && res.result.is_finished) {
            dispatch({
              type: "error",
              payload:
                "Please try again with another product.",
            });
          } else {
            dispatch({
              type: "error",
              payload:
                "Error occured while fetching recommended products. Please try again.",
            });
          }
        }
        dispatch({ type: "loading", payload: false });
      }
    );
  }, [product_id, title, category_id, root_category_id, country]); // The empty array causes this effect to only run on mount

  return (
    <div className="App">
      <Container className="" style={{ maxWidth: "1240px" }}>
        <div className="py-5 border-bottom">
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Stack direction="horizontal" gap={3}>
                <img src={logo} alt="AliScraper" style={{width: '160px'}} />
              </Stack>
            </Col>
          </Row>
          {state.product_name && state.product_url ? (
            <Row>
              <Col>
                <div className="main-product-details">
                  <Row className="label">
                    <Col>Product name</Col>
                  </Row>
                  <Row className="details">
                    <Col className="product-name" xs={8}>
                      {state.product_name}
                    </Col>
                    <Col className="product-btn-div">
                      <Button
                        href={state.product_url}
                        target="_blank"
                        variant="primary"
                        className="import-button-recommendation"
                      >
                        View Product
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col></Col>
            </Row>
          ) : (
            ""
          )}
        </div>

        <div className="py-5 border-bottom">
          <Row>
            <Col className="result-title">{state.error ? (<center>No recommended products found!</center>): "Recommended Products"}</Col>
          </Row>
          {state.loading === false && state.product_list ? (
            <ProductList product_list={state.product_list} import_btn_click_event="recommendation__add-to-import-pc--clicked" />
          ) : (
            <Row>
              {state.error ? (
                <Col style={{textAlign: 'center'}}>
                    <p >{state.error}</p>
                    <img src={NoImport} alt="No Import" className="img-fluid" style={{height: '450px'}} />
                </Col>
              ) : (
                <Col>
                  <div className="spinner-container">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};
export default Recommendation;
