import "./App.css";
import React from "react";
import { HashRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Recommendation from "./component/Recommendation";
import Search from "./component/Search";
import SearchResult from "./component/SearchResult";


function App(props) {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/recommendation/:product_id/:title/:category_id/:root_category_id/:country"
          element={<Recommendation />}
        />
        <Route path="/recommendation" element={<Recommendation />} />
        <Route exact path="/searchresult/:image_url" element={<SearchResult />} />
        <Route exact path="/searchresult" element={<SearchResult />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/" element={<Search />} />
      </Routes>
    </HashRouter>
  );
}

export default App;