import React, { useEffect, useReducer } from "react";
import { Button, Row, Col, Card, Modal } from "react-bootstrap";
import iconStar from "./../assets/img/yellow-star.svg";
import "bootstrap-icons/font/bootstrap-icons.css";

const initialState = {
  chrome_modal_show: false,
  dont_show_modal_checked: false
};

function reducer(state, action) {
  switch (action.type) {
    case "chrome_modal_show":
      return {
        ...state,
        chrome_modal_show: action.payload,
      };
    case "dont_show_modal_checked":
      return {
        ...state,
        dont_show_modal_checked: action.payload,
      };
    default:
      throw new Error();
  }
}

function ProductList(props) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const chrome_extension_url = "https://chrome.google.com/webstore/detail/aliscraper-aliexpress-sho/hdlajobndamjlloioebnannnlbopndee";

  let chrome_extension_installed = false;
  window.addEventListener("message", (message) => {
    if (
      message.data.type ===
      "import-button-recommendation-clicked-in-iframe-received"
    ) {
        chrome_extension_installed = true;
    }
  });

  const handleChromeModalClose = () => {
    dispatch({
      type: "chrome_modal_show",
      payload: false,
    });
  };

  const handleChromeModalShow = (e) => {
    sendImportMessageToChrome(e);

    if (!chrome_extension_installed) {
        // wait for few micro seconds to receive the message back
        window.setTimeout(function () {
            if (!chrome_extension_installed) {
                if (!state.dont_show_modal_checked) {
                    dispatch({
                      type: "chrome_modal_show",
                      payload: true,
                    });
                } else {
                    window.open(chrome_extension_url);
                }
            }
        }, 100);
    }
    

    trackEvent();
  };

  const handleCancelButtonClick = () => {
    dispatch({
      type: "chrome_modal_show",
      payload: false,
    });
  };

  const hancleDontShowCheckboxChange = (e) => {
    try {
      window.localStorage.setItem(
        "aliscraper_chrome_modal_dont_show",
        e.target.checked ? "true" : "false"
      );
    } catch (ex) {}

    dispatch({
        type: "dont_show_modal_checked",
        payload: e.target.checked ? true : false,
    });
  };

  const sendImportMessageToChrome = (e) => {
    try {
      window.parent.postMessage(
        {
          type: "import-button-recommendation-clicked-in-iframe",
          productid: e.target.getAttribute("productid"),
        },
        "*"
      );
    } catch (ex) {}

    try {
      window.postMessage(
        {
          type: "import-button-recommendation-clicked-in-iframe",
          productid: e.target.getAttribute("productid"),
        },
        "*"
      );
    } catch (ex) {}
  };

  const trackEvent = () => {
    if (props.import_btn_click_event) {
      window.analytics.track(props.import_btn_click_event);
    }
  };

  useEffect(() => {
    let dont_show_modal_checked = false;
    try {
      var temp = window.localStorage.getItem(
        "aliscraper_chrome_modal_dont_show"
      );
      if (temp && temp === "true") {
        dont_show_modal_checked = true;
      }
    } catch (ex) {}

    dispatch({
      type: "dont_show_modal_checked",
      payload: dont_show_modal_checked,
    });
  }, []);

  return (
    <Row className="mx-auto">
      {props.product_list.map(function (object, i) {
        object.display_price =
          object.sale_price ||
          object.target_sale_price ||
          object.original_price ||
          object.target_original_price;

        return (
          <Col key={object.product_id} className="product-item mx-auto">
            <Card>
              <a
                href={object.product_detail_url}
                className="product-link"
                rel="noreferrer"
                target="_blank"
              >
                <Card.Img variant="top" src={object.product_main_image_url} />
                <Card.Body>
                  <Card.Title>{object.product_title}</Card.Title>
                  <Card.Text>
                    <div className="float-start">
                      <div className="text-muted cost">Cost</div>
                      <div className="cost-price">
                        US $
                        {object.display_price.indexOf(".") > -1 ? (
                          <span>
                            <span className="digits">
                              {object.display_price.split(".")[0]}
                            </span>
                            <span className="points">
                              {object.display_price.split(".")[1]}
                            </span>
                          </span>
                        ) : (
                          object.display_price
                        )}
                      </div>
                    </div>
                    {object.evaluate_rate ? (
                      <div className="stars float-end">
                        <img src={iconStar} alt="Ratings" />
                        &nbsp;
                        {Math.round(
                          ((parseInt(object.evaluate_rate) * 5) / 100) * 10
                        ) / 10}
                      </div>
                    ) : (
                      ""
                    )}
                    <div style={{ clear: "both" }}></div>
                  </Card.Text>
                    <Button
                      onClick={handleChromeModalShow}
                      target="_blank"
                      variant="primary"
                      className="import-button-recommendation"
                      productid={object.product_id}
                      style={{ zIndex: 1 }}
                    >+ Import to Spocket</Button>
                  
                </Card.Body>
              </a>
            </Card>
          </Col>
        );
      })}
      <Modal
        className="chrome-modal"
        show={state.chrome_modal_show}
        onHide={handleChromeModalClose}
      >
        <Modal.Body>
          <Row>
            <Col>
              <center>
                <label>
                  You will be redirected to Chrome Extension Web Store.
                </label>
              </center>
            </Col>
          </Row>
          <Row style={{ marginTop: "23px" }}>
            <Col>
              <center>
                <Button
                  className="btn btn-light btn-cancel"
                  onClick={handleCancelButtonClick}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button className="btn btn-light btn-ok" href={chrome_extension_url} target="_blank">
                  OK&nbsp;&nbsp;<i className="bi bi-box-arrow-up-right"></i>
                </Button>
              </center>
            </Col>
          </Row>
          <Row style={{ marginTop: "22px" }}>
            <Col>
              <div className="form-check mx-auto" style={{ maxWidth: "250px" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="dontshow"
                  style={{ border: "1.5px solid rgba(8, 31, 64, 0.3)" }}
                  onChange={hancleDontShowCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="dontshow"
                  style={{ fontSize: "14px", color: "#55595F" }}
                >
                  Don't show me this message again
                </label>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Row>
  );
}

export default ProductList;
