import React, { useEffect, useReducer } from "react";
import logo from "./../assets/img/logo.svg";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import SearchForm from "./SearchForm";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { useNavigate, Link } from "react-router-dom";
import uploadImg from "./../assets/img/upload-img.svg";
import * as Common from "./Common";

const initialState = {
  iniframe: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "iniframe":
      return {
        ...state,
        iniframe: action.payload,
      };

    default:
      throw new Error();
  }
}

function Search() {
  const navigate = useNavigate();
  const maxUploadSize = 102400;
  const [state, dispatch] = useReducer(reducer, initialState);

  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
      <div style={{ height: "100%" }}>
        {previews}

        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

        {files.length > 0 && submitButton}
      </div>
    );
  };

  const inputContent = () => {
    return (
      <div>
        <div style={{ marginTop: "50px" }}>
          <img src={uploadImg} className="img-fluid" alt="Upload" />
        </div>
        <div>
          <label className="up">
            <span className="up">Upload a file</span> or drag and drop
          </label>
          <br />
          <label className="down">PNG, JPG, GIF, WEBP up to 100KB</label>
        </div>
      </div>
    );
  };

  const handleFileChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      if (meta.size < maxUploadSize) {
        var fr = new FileReader();
        fr.onload = function () {
          Common.setImageDataUrl(fr.result);
          navigate("../searchresult", { replace: false });
        };
        fr.readAsDataURL(file);
      } else {
        alert("Please upload PNG, JPG, GIF or WebP with less than 100KB");
      }
    }
  };

  useEffect(() => {
    if (window.location !== window.parent.location) {
      dispatch({ type: "iniframe", payload: true });
    } else {
      dispatch({ type: "iniframe", payload: false });
    }

    // if website is loaded first time and image data is available
    // load the search result
    var first_load = document.getElementById("aliscraper_first_load");
    if (first_load.value === "true") {
      first_load.value = "false";
      let image = Common.getImageDataUrl();
      if (image !== "") {
        navigate("../searchresult", { replace: true });
      }
    }
  }, [navigate]);

  return (
    <Container className="" style={{ maxWidth: "1240px" }}>
      <div className="d-flex justify-content-center" style={{ height: "100vh" }}>
        <div style={{ marginTop: "50px", width: "100%", maxWidth: "600px" }}>
          <div>
            <Row className="align-items-center">
              <Col xs="auto" className="mx-auto mt-2">
                <Link to="/" className="logo-link">
                  {state.iniframe ? (
                    <span className="spocket-txt-logo-large">Spocket Image Search</span>
                  ) : (
                    <img src={logo} alt="AliScraper" style={{ height: "50px" }} className="img-fluid" />
                  )}
                </Link>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "60px" }}>
            <SearchForm />
          </div>
          <div className="file-drp-zone">
            <Dropzone
              onChangeStatus={handleFileChangeStatus}
              accept="image/*"
              LayoutComponent={Layout}
              multiple={false}
              inputContent={inputContent}
              minSizeBytes={1}
              maxSizeBytes={maxUploadSize}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
export default Search;
